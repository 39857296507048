/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Formik } from 'formik';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';
import { FormattedMessage } from 'react-intl';
import { signin } from '../../client';

const Login = (props) => {
  const [message, setErrorMessage] = useState('');
  // eslint-disable-next-line react/destructuring-assignment
  const [successMessage, setSuccessMessage] = useState(props.location.state);

  const queryString = qs.parse(window.location.search.replace(/\?/g, ''));

  const defaultClientId = process.env.REACT_APP_DEFAULT_CLIENT_ID;

  const clientId =
    isEmpty(queryString) || !queryString.client_id
      ? defaultClientId
      : queryString.client_id;

  return (
    <div className="log-reg-page">
      <div className="log-reg-wrap">
        <h4>Login to access Dashboard</h4>
        {message && <p className="alert alert-danger">{message}</p>}
        {successMessage && (
          <p className="alert alert-info">{successMessage.message}</p>
        )}
        <Formik
          initialValues={{
            username: '' || Cookies.get('username'),
            password: '',
            client_id: clientId,
            rememberMe: '',
          }}
          validate={(values) => {
            const errors = {};

            if (!values.username) {
              errors.username = 'Please enter username';
            }

            if (!values.password) {
              errors.password = 'Please enter password';
            }

            return errors;
          }}
          onSubmit={(credentials, { setSubmitting }) => {
            setErrorMessage('');
            setSuccessMessage('');
            signin(credentials)
              .then(({ data }) => {
                if (credentials.rememberMe) {
                  const { username } = credentials;
                  Cookies.set('username', username, { expires: 7 });
                }
                // eslint-disable-next-line no-undef
                window.location.replace(
                  `${data.redirect_url}/?token=${data.token}`
                );
                setSubmitting(false);
                return null;
              })
              .catch((error) => {
                setSubmitting(false);
                setErrorMessage(error.message);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="log-reg-inputs-wrap">
                <Form.Group>
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    placeholder="Username, Email or Mobile Number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    // defaultValue={Cookies.get('username')}
                  />
                  <span style={{ color: 'red' }}>
                    {errors.username && touched.username && errors.username}
                  </span>
                </Form.Group>
                <Form.Group>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <span style={{ color: 'red' }}>
                    {errors.password && touched.password && errors.password}
                  </span>
                </Form.Group>
              </div>
              <Button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary btn-log-reg"
              >
                {isSubmitting && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Sign In
              </Button>
              <div className="d-flex justify-content-between my-2">
                {/* <div className="custom-control custom-checkbox my-1"> */}
                <div className="my-1">
                  <Form.Group>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        name="rememberMe"
                        onChange={() =>
                          setFieldValue('rememberMe', !values.rememberMe)
                        }
                        checked={values.rememberMe}
                      />
                      <Form.Check.Label>
                        <FormattedMessage
                          id="app.remember.me"
                          defaultMessage="Remember Me"
                        />
                      </Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                </div>
                {/* <Checkbox
                    name="rememberMe"
                    id="rememberMe"
                    onChange={() =>
                      setFieldValue('rememberMe', !values.rememberMe)
                    }
                    checked={values.rememberMe}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  /> */}
                <Link
                  to="/forgotpassword"
                  className="text-info text-underline my-1"
                >
                  Forgot Password
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Login;
