import React from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { IntlProvider } from 'react-intl';
import Login from './app/Login';
import ForgotPassword from './app/ForgotPassword';
import './assets/font-icons/style.css';
import './main.scss';
import ResetPassword from './app/ResetPassword';

/**
 * @function
 * @name App
 * @description Application Entry point
 *
 * @returns {object} React component
 *
 * @version 0.1.0
 * @since 0.1.0
 */
const App = () => (
  <IntlProvider>
    <ToastProvider placement="top-center">
      <HashRouter hashType="hashbang">
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/changepassword" component={ResetPassword} />
          <Redirect to="/" />
        </Switch>
      </HashRouter>
    </ToastProvider>
  </IntlProvider>
);

export default App;
