import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import omit from 'lodash/omit';
import Spinner from 'react-bootstrap/Spinner';
import validator from 'validator';
import { forgotPassword } from '../../client';

const renderSuccessMessage = (message) => {
  return (
    <div className="alert alert-info">
      <span style={{ letterSpacing: '1.2px' }}>
        <p>
          An email has been sent to <strong>{message}</strong>. Login to that
          address to reset your password
        </p>
      </span>
    </div>
  );
};

const renderErrorMessage = (message) => {
  return (
    <div className="alert alert-danger">
      <span style={{ letterSpacing: '1.0px' }}>{message}</span>
    </div>
  );
};

/**
 * @function
 * @name ForgotPassword
 * @description Forgot password page
 *
 * @returns {object} React component
 *
 * @version 0.1.0
 * @since 0.1.0
 */
const ForgotPassword = () => {
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <div className="log-reg-page">
      <div className="log-reg-wrap">
        <h5>Forgot your Password?</h5>
        <span>Enter your email below to receive a password reset link.</span>
        {message && renderSuccessMessage(message)}
        {errorMessage && renderErrorMessage(errorMessage)}
        <div className="log-reg-inputs-wrap">
          <Formik
            initialValues={{
              email: '',
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Email is Required';
              } else if (!validator.isEmail(values.email)) {
                errors.email = 'Please enter valid email';
              }
              return errors;
            }}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              setErrorMessage('');
              setMessage('');

              const vendor = omit(data, ['confirmPassword']);
              forgotPassword(vendor)
                .then(() => {
                  // history.push('/forgotpassword');
                  setMessage(data.email);
                  setSubmitting(false);
                })
                .catch((error) => {
                  setSubmitting(false);
                  setErrorMessage(error.message);
                });
            }}
          >
            {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit} className="">
                <Form.Group>
                  <Form.Control
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Email Address"
                    value={values.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <span style={{ color: 'red' }}>{errors.email}</span>
                  )}
                </Form.Group>
                <button
                  type="submit"
                  className="btn btn-primary btn-log-reg"
                  disabled={!isEmpty(errors)}
                >
                  {isSubmitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Recover Password
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <p className="text-center">
          <Link to="/" className="text-info">
            <i className="fa fa-long-arrow-alt-left" /> Back to Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
