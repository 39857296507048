/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Formik } from 'formik';
import { changePassword } from '../../client';

const renderSuccessMessage = (message) => {
  return (
    <div className="alert alert-info">
      <span style={{ letterSpacing: '1.2px' }}>
        <strong>{message}</strong>
      </span>
      <br />
    </div>
  );
};

const renderErrorMessage = (message) => {
  return (
    <div className="alert alert-danger">
      <span style={{ letterSpacing: '1.0px' }}>{message}</span>
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

const ResetPassword = ({ history, location }) => {
  const [message] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  return (
    <div className="log-reg-page">
      <div className="log-reg-wrap">
        <h4>Broadcaster Reset Password</h4>

        {message && renderSuccessMessage(message)}
        {errorMessage && renderErrorMessage(errorMessage)}

        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
            token,
          }}
          validate={(values) => {
            const errors = {};

            if (!values.password) {
              errors.password = 'Password is Required';
            }

            if (values.password !== values.confirmPassword) {
              errors.mismatch =
                'Password Mismatch, Please Make Sure Password and Confirm Password are Equal';
            }

            return errors;
          }}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            const vendor = omit(data, ['confirmPassword']);
            changePassword(vendor)
              .then((response) => {
                history.replace({
                  pathname: '/',
                  search: `?client_id=${response.data.client_id}`,
                  state: { message: response.data.message },
                });
                setSubmitting(false);
              })
              .catch((error) => {
                setSubmitting(false);
                setErrorMessage(error.message);
              });
          }}
        >
          {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} className="log-reg-inputs-wrap">
              <Form.Group>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="New Password"
                  onChange={handleChange}
                  value={values.password}
                />
              </Form.Group>
              {errors.password && (
                <span style={{ color: 'red' }}>{errors.password}</span>
              )}
              <Form.Group>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm New Password"
                  onChange={handleChange}
                  value={values.confirmPassword}
                />
                {errors.mismatch && (
                  <span style={{ color: 'red' }}>{errors.mismatch}</span>
                )}
              </Form.Group>
              <button
                type="submit"
                className="btn btn-primary btn-log-reg"
                disabled={!_.isEmpty(errors)}
              >
                {isSubmitting && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Change Password
              </button>
            </Form>
          )}
        </Formik>

        <p>
          Do you have account?{' '}
          <Link to="/">
            <strong>Login</strong>
          </Link>
        </p>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
export default ResetPassword;
