import { merge, forEach } from 'lodash';
import { createHttpActionsFor } from './client';

/* API resources/endpoints that are accessible to the web */

const API_RESOURCES = [
  {
    name: 'vendor',
  },
];

/* http actions exposed by this client */
export const httpActions = {};

export * from './client';

forEach(API_RESOURCES, (resource) => {
  const resourceHttpActions = createHttpActionsFor(resource);
  merge(httpActions, resourceHttpActions);
});
