import axios from 'axios';
import { merge, isEmpty, camelCase, forEach } from 'lodash';
import { singularize, pluralize } from 'inflection';

/* declarations */
let client;
let BASE_URL;
let userId;
let jwtToken;

/**
 * @function
 * @name getJwtToken
 * @description retrieve jwt token from local storage if not set
 * @returns {string| undefined} jwt token
 * @since 0.1.0
 * @version 0.1.1
 */
const getJwtToken = () => {
  if (isEmpty(jwtToken)) {
    jwtToken = localStorage.getItem('token'); // eslint-disable-line
  }

  return jwtToken;
};

/**
 * @function
 * @name getUserId
 * @description retrieve userId from local storage if not set
 *
 * @returns {string |undefined} user id
 * @since 0.1.0
 * @version 0.1.0
 */
export const getUserId = () => {
  if (isEmpty(userId)) {
    userId = localStorage.getItem('userId'); // eslint-disable-line
  }

  return userId;
};

/**
 * @function
 * @name isValidUser
 * @description Valid if the current user is valid
 * @returns {boolean} True if valid and false otherwise
 * @version 0.1.0
 * @since 0.1.0
 */
export const isValidUser = () => {
  jwtToken = getJwtToken();

  userId = getUserId();

  if (jwtToken && userId) {
    return true;
  }
  return false;
};

/**
 * @function mapResponseToError
 * @name mapResponseToError
 * @description convert axios error to js native error
 * @param {object} exception axios http error response
 * @returns {Promise} promise rejection
 * @see {@link https://github.com/axios/axios#handling-errors}
 * @since 0.1.0
 * @version 0.1.0
 * @private
 */
const mapResponseToError = (exception) => {
  // obtain error details
  let { code, status, message, description, stack, errors, data } = exception;
  const { request, response } = exception;

  // handle server response error
  if (response) {
    code = response.code || code;
    status = response.status || status;
    data = response.data || data || {};
    message =
      response.status === undefined || status === undefined
        ? "We're having trouble processing your request. Please try again later or contact support for assistance"
        : data.message || response.statusText || message;
    errors = response.errors || errors || {};
    stack = response.stack || data.stack || stack;
  }

  // handle no server response
  if (request) {
    description = description || 'Server Not Responding';
  }

  // initialize error
  let error = new Error(message);
  error.stack = stack;

  // update error object
  error = merge(error, {
    code,
    status,
    message:
      status === undefined
        ? "We're having trouble processing your request. Please try again later or contact support for assistance"
        : message,
    description,
    errors,
    ...data,
    ...response,
  });

  // return normalized native error
  return Promise.reject(error);
};
/**
 * @function mapResponseToData
 * @name mapResponseToData
 * @description convert axios http response to data
 * @param {object} response axios http response
 * @returns {object} response data
 * @since 0.1.0
 * @version 0.1.0
 * @private
 */
const mapResponseToData = (response) => response.data;

/**
 * @function wrapRequest
 * @name wrapRequest
 * @description wrap http request and convert response to error or data
 * @param {Promise} request valid axios http request object
 * @returns {Promise} request with normalized response error and data
 * @since 0.1.0
 * @version 0.1.0
 * @private
 */
const wrapRequest = (request) => {
  return request.then(mapResponseToData).catch(mapResponseToError);
};

/**
 * @name CONTENT_TYPE
 * @description supported content type
 * @since 0.1.0
 * @version 0.1.0
 * @static
 * @public
 */
export const CONTENT_TYPE = 'application/json';

/**
 * @name HEADERS
 * @description default http headers
 * @since 0.1.0
 * @version 0.1.0
 * @static
 * @public
 */
export const HEADERS = {
  Accept: CONTENT_TYPE,
  'Content-Type': CONTENT_TYPE,
  Authorization: `Bearer ${getJwtToken()}`,
};

/**
 * @function createHttpClient
 * @name createHttpClient
 * @description create an http client if not exists
 * @param {string} API_BASE_URL base url to use to api calls
 * @returns {object} A new instance of Axios
 * @since 0.1.0
 * @version 0.1.0
 * @static
 * @public
 * @example
 * import { createHttpClient } from './client';
 * const httpClient = createHttpClient();
 */
export const createHttpClient = (API_BASE_URL) => {
  if (!client) {
    const { BEEM_API_URL } = process.env;
    const { REACT_APP_BEEM_API_URL } = process.env;
    BASE_URL = API_BASE_URL || BEEM_API_URL || REACT_APP_BEEM_API_URL;
    const options = { baseURL: BASE_URL, headers: HEADERS };
    client = axios.create(options);
    client.id = Date.now();
  }

  return client;
};

/**
 * @function disposeHttpClient
 * @name disposeHttpClient
 * @description reset current http client in use.
 * @returns {object} null
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { disposeHttpClient } from './client';
 * disposeHttpClient();
 */
export const disposeHttpClient = () => {
  client = null;
  return client;
};

/**
 * @function all
 * @name all
 * @description performing multiple concurrent requests.
 * @param {Function[]} promises Array of function to be run in parallel
 * @returns {Promise} Promise Instance
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { all, spread } from './client';
 * const request = all(getProfile(), getPlans());
 * request.then(spread((incidentTypes, plans) => { ... }));
 */
export const all = (...promises) => axios.all([...promises]);

/**
 * @function spread
 * @name spread
 * @description Flattened array fulfillment to the formal parameters of the
 * fulfillment handler.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { all, spread } from './client';
 * const request = all(getProfile(), getPlans());
 * request.then(spread((incidentTypes, plans) => { ... }));
 */
export const spread = axios.spread; // eslint-disable-line

/**
 * @function prepareParams
 * @name prepareParams
 * @description convert api query params as per API filtering specifications
 * @param {object} params api call query params
 * @returns {object} http params to be sent to server
 * @since 0.1.0
 * @version 0.1.0
 * @static
 * @public
 * @example
 */
export const prepareParams = (params) => {
  const normalizedParams = {};
  const { sort, page, pageSize, ...otherParams } = params || {};

  if (sort) {
    forEach(params.sort, (value, key) => {
      normalizedParams.sortBy = key;
      normalizedParams.sortOrder = value;
    });
  }

  if (page) {
    normalizedParams.page = params.page;
  }

  if (pageSize) {
    normalizedParams.pageSize = params.pageSize;
  }

  if (otherParams) {
    forEach(otherParams, (value, key) => {
      normalizedParams[key] = value;
    });
  }

  return normalizedParams;
};

/**
 * @function get
 * @name get
 * @description issue http get request to specified url.
 * @param {string} url valid http path.
 * @param {object} [params] params that will be encoded into url query params.
 * @returns {Promise} promise resolve with data on success or error on failure.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { get } from './client';
 *
 * // list
 * const getUsers = get('/users', { age: 10 });
 * getUsers.then(users => { ... }).catch(error => { ... });
 *
 * // single
 * const getUser = get('/users/12');
 * getUser.then(user => { ... }).catch(error => { ... });
 */
export const get = (url, params) => {
  const httpClient = createHttpClient();
  const options = prepareParams(params);
  return wrapRequest(httpClient.get(url, { params: options }));
};

/**
 * @function post
 * @name post
 * @description issue http post request to specified url.
 * @param {string} url valid http path.
 * @param {object} data request payload to be encoded on http request body
 * @returns {Promise} promise resolve with data on success or error on failure.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { post } from './client';
 *
 * const postUser = post('/users', { age: 14 });
 * postUser.then(user => { ... }).catch(error => { ... });
 */
export const post = (url, data) => {
  if (isEmpty(data)) {
    return Promise.reject(new Error('Missing Payload'));
  }
  const httpClient = createHttpClient();
  return wrapRequest(httpClient.post(url, data));
};

/**
 * @function put
 * @name put
 * @description issue http put request to specified url.
 * @param {string} url valid http path.
 * @param {object} data request payload to be encoded on http request body
 * @returns {Promise} promise resolve with data on success or error on failure.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { put } from './client';
 *
 * const putUser = put('/users/5c1766243c9d520004e2b542', { age: 11 });
 * putUser.then(user => { ... }).catch(error => { ... });
 */
export const put = (url, data) => {
  if (isEmpty(data)) {
    return Promise.reject(new Error('Missing Payload'));
  }
  const httpClient = createHttpClient();
  return wrapRequest(httpClient.put(url, data));
};

/**
 * @function patch
 * @name patch
 * @description issue http patch request to specified url.
 * @param {string} url valid http path.
 * @param {object} data request payload to be encoded on http request body
 * @returns {Promise} promise resolve with data on success or error on failure.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { patch } from './client';
 *
 * const patchUser = patch('/users/5c1766243c9d520004e2b542', { age: 10 });
 * patchUser.then(user => { ... }).catch(error => { ... });
 */
export const patch = (url, data) => {
  if (isEmpty(data)) {
    return Promise.reject(new Error('Missing Payload'));
  }
  const httpClient = createHttpClient();
  return wrapRequest(httpClient.patch(url, data));
};

/**
 * @function del
 * @name del
 * @description issue http delete request to specified url.
 * @param {string} url valid http path.
 * @returns {Promise} promise resolve with data on success or error on failure.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { del } from './client';
 *
 * const deleteUser = del('/users/5c1766243c9d520004e2b542');
 * deleteUser.then(user => { ... }).catch(error => { ... });
 */
export const del = (url) => {
  const httpClient = createHttpClient();
  return wrapRequest(httpClient.delete(url));
};

/**
 * @function singin
 * @name singin
 * @description Signin user with provided credentials
 * @param {object} credentials Username and password
 * @returns {object} Object having party, permission and other meta data
 * @since 0.1.0
 * @version 0.1.0
 * @static
 * @public
 * @example
 * import { signin } from './client';
 *
 * signin({ username:'', password:'' }).then(results => {});
 */
export const signin = (credentials) => {
  const defaultCredentials = { username: '', password: '' };
  const payload = isEmpty(credentials)
    ? defaultCredentials
    : merge(defaultCredentials, credentials);

  return post('/auth/login', payload).then((results) => {
    // persist token and party in session storage
    localStorage.setItem('token', results.data.access_token); // eslint-disable-line
    localStorage.setItem('userId', results.data.id); // eslint-disable-line
    userId = results.data.id;
    jwtToken = results.data.access_token;

    return results;
  });
};

export const forgotPassword = (email) => {
  return post('/auth/forgotpassword', email).then((results) => {
    return results;
  });
};

export const changePassword = (credentials) => {
  return post('/auth/changepassword', credentials).then((results) => {
    return results;
  });
};

/**
 * @function
 * @name getcountry
 * @version 0.1.0
 * @static
 * @public
 *
 */

export const getCountries = (param) => {
  return get('/static/countries', param);
};

/**
 * @function
 * @name signout
 * @description Signout function and cleanup localStorage
 *
 * @returns {undefined}
 * @version 0.1.0
 * @since 0.1.0
 */
export const signout = () => {
  localStorage.clear(); // eslint-disable-line
};

export const normalizeResource = (resource) => {
  const singular = singularize(resource.name);
  const plural = pluralize(resource.name);

  return { singular, plural };
};

/**
 * @function
 * @name variableNameFor
 * @description Generate camel cased variable name based on provided strings
 * @param  {...string} names array of names to be used in variable name
 * @returns {string} camel cased name
 *
 * @version 0.1.0
 * @since 0.1.0
 */
const variableNameFor = (...names) => camelCase([...names]);

/**
 * @function
 * @name createGetListHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createGetListHttpAction = (resource) => {
  const { plural } = normalizeResource(resource);

  const methodName = variableNameFor('get', plural);

  return {
    [methodName]: (params) => {
      const endpoint = `/${plural}`;

      return get(endpoint, params);
    },
  };
};

/**
 * @function
 * @name createGetSingleHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createGetSingleHttpAction = (resource) => {
  const { singular, plural } = normalizeResource(resource);

  const methodName = variableNameFor('get', singular);

  return {
    [methodName]: (id) => {
      const endpoint = `/${plural}/${id}`;

      return get(endpoint);
    },
  };
};

/**
 * @function
 * @name createPostHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createPostHttpAction = (resource) => {
  const { singular, plural } = normalizeResource(resource);

  const methodName = variableNameFor('post', singular);

  return {
    [methodName]: (payload) => {
      const endpoint = `/${plural}`;

      return post(endpoint, payload);
    },
  };
};

/**
 * @function
 * @name createPutHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createPutHttpAction = (resource) => {
  const { singular, plural } = normalizeResource(resource);

  const methodName = variableNameFor('put', singular);

  return {
    [methodName]: (payload) => {
      const endpoint = `/${plural}/${payload.id}`;

      return put(endpoint, payload);
    },
  };
};

/**
 * @function
 * @name createPatchHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createPatchHttpAction = (resource) => {
  const { singular, plural } = normalizeResource(resource);

  const methodName = variableNameFor('patch', singular);

  return {
    [methodName]: (payload) => {
      const endpoint = `/${plural}/${payload.id}`;

      return patch(endpoint, payload);
    },
  };
};

/**
 * @function
 * @name createDeleteHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createDeleteHttpAction = (resource) => {
  const { singular, plural } = normalizeResource(resource);

  const methodName = variableNameFor('delete', singular);

  return {
    [methodName]: (id) => {
      const endpoint = `/${plural}/${id}`;

      return del(endpoint);
    },
  };
};

/**
 * @function createHttpActionsFor
 * @name createHttpActionsFor
 * @description generate http actions to interact with resource
 * @param {string} resource valid http resource
 * @returns {object} http actions to interact with a resource
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { createHttpActionsFor } from './client';
 *
 * const { deleteUser } = createHttpActionsFor('user');
 * deleteUser('5c176624').then(user => { ... }).catch(error => { ... });
 */
export const createHttpActionsFor = (resource) => {
  const getResources = createGetListHttpAction(resource);
  const getResource = createGetSingleHttpAction(resource);
  const postResource = createPostHttpAction(resource);
  const putResource = createPutHttpAction(resource);
  const patchResource = createPatchHttpAction(resource);
  const deleteResource = createDeleteHttpAction(resource);

  return {
    ...getResources,
    ...getResource,
    ...postResource,
    ...putResource,
    ...patchResource,
    ...deleteResource,
  };
};
